window.initializeGmaps = function () {
    var $gmaps = $('.gmap');
    if($gmaps.length){
        var pinUrl = location.protocol+'//'+location.hostname+'/themes/amsterdamair2021/assets/dest/img/pins/';
        // https://snazzymaps.com/
        var styles = [{
            'featureType': 'administrative.province',
            'elementType': 'all',
            'stylers': [{'visibility': 'off'}]
        }, {
            'featureType': 'landscape',
            'elementType': 'all',
            'stylers': [{'saturation': -100}, {'lightness': 65}, {'visibility': 'on'}]
        }, {
            'featureType': 'poi',
            'elementType': 'all',
            'stylers': [{'saturation': -100}, {'lightness': 51}, {'visibility': 'off'}]
        }, {
            'featureType': 'road.highway',
            'elementType': 'all',
            'stylers': [{'saturation': -100}, {'visibility': 'simplified'}]
        }, {
            'featureType': 'road.highway',
            'elementType': 'geometry',
            'stylers': [{'color': '#F0F0F0'}]
        }, {
            'featureType': 'road.highway',
            'elementType': 'labels',
            'stylers': [{ 'visibility': 'off'}]
        }, {
            'featureType': 'road.arterial',
            'elementType': 'all',
            'stylers': [{'saturation': -100}, {'lightness': 30}, {'visibility': 'on'}]
        }, {
            'featureType': 'road.local',
            'elementType': 'all',
            'stylers': [{'saturation': -100}, {'lightness': 40}, {'visibility': 'on'}]
        }, {
            'featureType': 'transit',
            'elementType': 'all',
            'stylers': [{'saturation': -100}, {'visibility': 'simplified'}]
        }, {
            'featureType': 'transit',
            'elementType': 'geometry.fill',
            'stylers': [{'visibility': 'on'}]
        }, {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [{'lightness': -25}, {'saturation': -97}, {'color': '#5d9b9b'}]
        }, {
            'featureType': 'water',
            'elementType': 'labels',
            'stylers': [{'visibility': 'on'}, {'lightness': -25}, {'saturation': -100}]
        }, {
            'featureType': 'poi',
            'elementType': 'labels',
            'stylers': [{visibility: 'off'}]
        }];
        var infoWindows = [];
        $gmaps.each(function(){
            // Affichage de la map
            var $gmap = $(this);
            var gmapContainer = $('.map-container', $gmap)[0];
            var $data = $('ul.map--data', $gmap);
            var mapLat = $data.data('lat');
            var mapLng = $data.data('lng');
            var mapLatLng = {lat: mapLat, lng: mapLng};
            var mapZoom = $data.data('zoom');
            var $markers = $('.pin', $gmap);
            mapOptions = {
                center: mapLatLng,
                mapTypeId: 'roadmap',
                gestureHandling: 'cooperative',
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                streetViewControl: false,
                zoom: mapZoom,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
                },
                fullscreenControl: true,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_RIGHT
                }
            };
            map = new google.maps.Map(gmapContainer, mapOptions);
            map.setOptions({styles: styles});
            // Affichage des pins
            $markers.each(function(){
                var $marker = $(this);
                var address = $marker.data('address');
                var pinLat = $marker.data('lat');
                var pinLng = $marker.data('lng');
                var pinLatLng = {lat: pinLat, lng: pinLng};
                var infoWindowContent = $($marker).html();
                var type = $($marker).data('type')
                var marker = new google.maps.Marker({
                    map: map,
                    position: pinLatLng,
                    title: address,
                    animation: google.maps.Animation.DROP,
                    icon: pinUrl + 'pin-' + type + '.svg',
                });
                var infowindow = new google.maps.InfoWindow({
                    content: '<div id="info-window-content">'+infoWindowContent+'</div>'
                });
                marker.addListener('click', function() {
                    closeAllInfoWindows();
                    infowindow.open(map, marker);
                });
                $marker[0].addEventListener('click', function() {
                    closeAllInfoWindows();
                    infowindow.open(map, marker);
                });
                infoWindows.push(infowindow);
            });
            // Recentrage de la map si plusieurs pins
            var bounds = new google.maps.LatLngBounds();
            var pinsLatLng = [];
            $markers.each(function(){
                var $marker = $(this);
                var lat = $marker.data('lat');
                var lng = $marker.data('lng');
                pinsLatLng.push( new google.maps.LatLng(lat, lng));
            });
            for(var i = 0; i < pinsLatLng.length; i++){
                bounds.extend(pinsLatLng[i]);
            }
            if(pinsLatLng.length > 1){
                map.fitBounds(bounds);
            }
        });
        function closeAllInfoWindows() {
            for (var i=0;i<infoWindows.length;i++) {
                infoWindows[i].close();
            }
        }
    } else if (document.getElementById('map') && typeof initializePrimeGmaps === 'function') {
        initializePrimeGmaps();
    }
};