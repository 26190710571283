$(document).ready(function () {
    const $htmlBody = $('html, body');

    $('#post-footer span').on('click', function (e) {
        var $parent = $(this).parent();
        var href = $(this).attr('data-href');
        if ($parent.hasClass('facebook')) {
            javascript:window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            return false;
        } else if ($parent.hasClass('twitter')) {
            javascript:window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            return false;
        } else {
            javascript:window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            return false;
        }
        e.preventDefault();
    });

    if ($('.slider').length) {
        $sliderArray = new Array();
        $('.slider').each(function (index, value) {
            var $wrap = $(this);
            var $frame = $('.frame', this);
            $frame.attr('id', 'sly-' + (index + 1));
            $frame.attr('data-sly-index', index);
            var $options = {
                horizontal: 1,
                itemNav: 'forceCentered',
                smart: 1,
                activateMiddle: 1,
                mouseDragging: 1,
                touchDragging: 1,
                releaseSwing: 1,
                startAt: 0,
                scrollBy: 1,
                speed: 600,
                dragHandle: 1,
                dynamicHandle: 1,
                pagesBar: $wrap.find('.pages'),
                prev: $wrap.find('.prev a'),
                next: $wrap.find('.next a'),
                activatePageOn: 'click',
                // Cycling
                cycleBy: 'items',
                cycleInterval: 3000,
                pauseOnHover: 1
            };
            $sliderArray.push(
                new Sly($frame, $options).init()
            );
        });
        $('.frame').sly('on', 'change', function () {
            var current = this.rel.activeItem;
            var $frame = $(this.frame);
            var $wrap = $frame.closest($('.wrap'));
            $('.galerie-caption ul li', $wrap).removeClass('active');
            $('.galerie-caption ul li:eq(' + current + ')', $wrap).addClass('active');
        });
        $(window).resize(function () {
            if ($('.slider').length) {
                for (var i = 0; i < $sliderArray.length; i++) {
                    $('.slider:eq(' + i + ') .frame .slidee li').width($('.slider:eq(' + i + ')').width());
                    $sliderArray[i].reload();
                }
            }
        }).resize();
        $(window).load(function () {
            if ($('.slider').length) {
                for (var i = 0; i < $sliderArray.length; i++) {
                    $('.slider:eq(' + i + ') .frame .slidee li').width($('.slider:eq(' + i + ')').width());
                    $sliderArray[i].reload();
                }
            }
        });
    }

    $(window).load(function () {
        if ($('#search-content #search-result .item').length) {
            $('#search-content #search-result .item .post-content').dotdotdot({
                ellipsis: ' [...]'
            });
            $(window).resize(function () {
                $('#search-content #search-result .item .post-content').dotdotdot({
                    ellipsis: ' [...]'
                });
            }).resize();
        }
    });

    if ($('.imgLiquidFill').length) {
        $('.imgLiquidFill').imgLiquid({
            fill: true,
            horizontalAlign: "center",  // 'center' //  'left'  //  'right'  // '50%'  // '10%'
            verticalAlign: "center",     // 'center' //  'top'   //  'bottom' // '50%'  // '10%'
            onStart: function () {
            },
            onFinish: function () {
            },
            onItemStart: function (index, container, img) {
            },
            onItemFinish: function (index, container, img) {
                container.addClass('visible');
            }
        });
    }

    if ($('#category-content').length) {
        if ($('.dotdotdot').length) {
            $('.dotdotdot').each(function () {
                $(this).attr('title', $(this).text());
            });
            $('.dotdotdot').dotdotdot();
            $(window).on('resize load', function () {
                $('.dotdotdot').dotdotdot();
            }).resize();
        }
    }

    if ($('#sofinco_sim_button').length) {
        $(document).on('click', '#sofinco_sim_button', function (e) {
            window.open('https://financement.transcred.com/sofgate.asp?q6=020L705&p0=PRODUIT&p4=' + document.getElementById('sofinco_p4').value);
        })
    }

    $('.tooltip').tooltipster({
        position: 'top',
        touchDevices: true
    });

    const $anchors = $('.layout-ancres');
    if ($anchors.length) {
        $('.la--item--link', $anchors).on('click', function(e) {
            e.preventDefault();
            let $id = $(this).attr('href');
            let $offsetTop = $($id).offset().top - $('header').innerHeight() - 20;
            $htmlBody.animate({
                scrollTop: $offsetTop
            }, 1000);
        });
    }
});