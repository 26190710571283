$(document).ready(function () {
    function $_GET(param) {
        let vars = {};
        window.location.href.replace(location.hash, '').replace(
            /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
            function(m, key, value) { // callback
                vars[key] = value !== undefined ? value : '';
            }
        );

        if (param) {
            return vars[param] ? vars[param] : null;
        }

        return vars;
    }

    let $page = $_GET('p');

    // init Masonry
    const $grid = $('.realisations--grid');
    if ($grid.length) {
        $grid.masonry();
        $grid.css({
            opacity: 0
        });

        $grid.imagesLoaded(function () {
            $grid.delay(1000).animate({
                opacity: 1
            });
            $grid.masonry({
                // options
                itemSelector: '.realisations--grid--item',
                columnWidth: '.realisations--grid--sizer',
                percentPosition: true,
            });
        });

        $grid.closest('.page--realisations').attr('data-is-masonry', 1);

        if ($page === null) {
            // get Masonry instance
            const $msnry = $grid.data('masonry');

            // init Infinite Scroll
            $grid.infiniteScroll({
                // Infinite Scroll options...
                path: '.pagination__next',
                checkLastPage: true,
                hideNav: '.pagination__next',
                append: '.realisations--grid--item',
                outlayer: $msnry,
                history: false,
                status: '.page-load-status',
                scrollThreshold: 1000,
            });
        }
    }
});